import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { wrap, mainContent } from '../components/styles';
import { AppState } from '../redux-modules/root';

import { ContainerProps } from './Container';
import * as client from '../redux-modules/client';
import * as dynamicclient from '../redux-modules/dynamicclient';
import * as family from '../redux-modules/family';
import * as Types from '../api/definitions';

import { KeyUpService } from '../Services/KeyUpService';
import { domainPath } from '../App';
import FamilyDetails from '../components/FamilySearchComponent/FamilyDetails';
interface MatchParams {
    index: string;
}
export interface DynamicFamilyDetailsContainerState {
    isLoading: boolean;
    error: string;
    hasError: boolean;
    program_completion_response: string | null;
}
export interface DynamicFamilyDetailsContainerProps extends ContainerProps<MatchParams>, WithSnackbarProps {
    searchDFamily: (
        client_code: string,
        ssn: string,
        first_name: string,
        last_name: string,
        dob: string,
        is_accessToken: any,
        version: number,
        id_n: string
    ) => Promise<void>;
    updateProgramCompletion2: (client: Types.DynamicClient) => Promise<string>;
    // getAvailablePrograms: () => Promise<void>;
    submitPrediction: (client: Types.Client) => Promise<void>;
    getLocations: (client_code: string, selected_program: string, is_accessToken: any) => Promise<void>;
    getPcr: (client_code: string, selected_program: string) => Promise<void>;
    DsaveLocationAndProgram: (
        selected_location: string,
        selected_program: string,
        client_code: string
    ) => Promise<void>;
    clearErrors: () => void;
    clearClient: () => void;
    getProgramsForClient: (client_code: string, version: string) => Promise<void>;
    updateFormValues: (client_code: string, values: any) => void;
    // getReferral: () => Promise<void>;
    Referral: Types.Referral[];
}

export class DynamicFamilyDetailsContainer extends React.Component<
    DynamicFamilyDetailsContainerProps,
    DynamicFamilyDetailsContainerState
> {
    constructor(props: DynamicFamilyDetailsContainerProps) {
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState() {
        return {
            isLoading: false,
            hasError: false,
            error: '',
            program_completion_response: null
        };
    }

    componentDidMount() {
        this.loadClientDetails();
    }

    loadClientDetails = async () => {
        const org_type: any = this.props && this.props.user && this.props.user.user && this.props.user.user.org_type;
        const locationVersionComplete =
            this.props && this.props.location && (this.props.location.state as Types.CustomizedHistoryState);
        const locationVersion: string = locationVersionComplete?.version;

        const versionIndex: any =
            locationVersion &&
            (parseInt(locationVersion.substring(1)) > 0
                ? parseInt(locationVersion.substring(1)) - 1
                : parseInt(locationVersion.substring(1)));

        const { client: clientState } = this.props;
        const clientList = (clientState && clientState.clientList) || {};
        const { index } = this.props.match.params;
        this.setState({ isLoading: true });
        let version = '';
        // if (clientList[index] || index) {
        //     let client_code: string = org_type === 1 ? index : '';
        //     let id_n: string = [2,3].includes(org_type) ? index : '';
        //     await this.searchClient(client_code, '', '', '', '', versionIndex, id_n);
        // }
        // fetch program for this client
        //let isExclusiveOrg = ['perseus-house', 'mhy', 'mhy-family-services'].includes(domainPath);
        let isExclusiveOrg = [ 'demo'].includes(domainPath);
        if (isExclusiveOrg) {
            await this.props.getProgramsForClient(index, version);
        }
        this.setState({ isLoading: false });
        this.props.closeSnackbar();
        // this.props.getAvailablePrograms();
        // this.props.getReferral();
    };
       
    searchClient = async (
        client_code: string,
        ssn: string,
        first_name: string,
        last_name: string,
        dob: string,
        version: number,
        id_n: string
    ) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        await this.props.searchDFamily(client_code, ssn, first_name, last_name, dob, is_accessToken, version, id_n);
    };

    
    updateProgramCompletion2 = async (clientForm: Types.DynamicClient) => {
        try {
            this.setState({ isLoading: true });
            await this.props.updateProgramCompletion2(clientForm);
            this.setState({
                isLoading: false
                // program_completion_response: response
            });
            this.props.enqueueSnackbar('Data saved successfully.');
        } catch (error) {
            console.log(error);
            this.setState({
                isLoading: false
                // program_completion_response: "An error occured. Please try again."
            });
            this.props.enqueueSnackbar('An error occured. Please try again.');
        }
    };

    getVersionDetails = async (client_code: string, version: string) => {
        this.setState({ isLoading: true });
        await this.props.getProgramsForClient(client_code, version);
        this.setState({ isLoading: false });
    };
    submitProgram = async (client: Types.Client) => {
        if (!client.client_code) {
            this.props.enqueueSnackbar('Error. Client information is required to process this form.');
            return false;
        }
        try {
            this.setState({ isLoading: true });
            await this.props.submitPrediction(client);
        } catch (error) {
            let errorMessage: string = 'An error occurred while saving.';
            if (error['referred_program']) {
                errorMessage = error['referred_program'][0];
            } else if (error.message) {
                errorMessage = error.message;
            }
            this.props.enqueueSnackbar(errorMessage);
        }
        this.setState({ isLoading: false });
    };

    render() {
        const { client: clientState, family: familyState,referral: referralState } = this.props;
        const referralList = (referralState && referralState.referralList) || [];
        const familyList = (familyState && familyState.familyList) || {};
        const { index, Config, subConfig } = KeyUpService.getConfSubConf(this.props.location.pathname);
        const { is_prediction_available }: any = this.props.user && this.props.user.user;
        const is_role_type: any = this.props.user && this.props.user.user.role_type;
        const uniqueIdField: any = (familyState && familyState.familyUniqueId) || '';
        const searchData: any =
            this.props && this.props.family && this.props.family.searchData && this.props.family.searchData;
        const org_type = this.props && this.props.user && this.props.user.user && this.props.user.user.org_type;
        //Find Version : 1. Open through Notifications -> Notifications version,
        //Find Version : 2. Open through Search Client -> Last Version
        // const locationVersion =
        //     this.props && this.props.location && this.props.location.state && this.props.location.state.version;
        const locationVersionComplete =
            this.props && this.props.location && (this.props.location.state as Types.CustomizedHistoryState);
        const locationVersion: string = locationVersionComplete?.version;
        const versionIndex = locationVersion
            ? parseInt(locationVersion.substring(1)) > 0
                ? parseInt(locationVersion.substring(1)) - 1
                : parseInt(locationVersion.substring(1))
            : searchData && searchData.length - 1;
            const refferalInfo: any = this.props.user && this.props.user.user.referral_info;   
            return (
                <div css={wrap}>
                    <div css={mainContent}>
                        {!familyList[index] ? null : (
                            <FamilyDetails
                                onVersionSelect={this.getVersionDetails}
                                searchData={searchData.filter((s) => s[uniqueIdField].toString() === index)}
                                // onProgramSelect={this.getLocationsAndPcr}
                                is_role_type={is_role_type}
                                is_prediction_available={is_prediction_available}
                                family={familyList[index]}
                                index={index}
                                DynamicQuestions={familyList[index].sections.filter(
                                    (sec) => sec.section === 'Outcomes'
                                )}
                                {...this.state}
                                org_type={org_type}
                                versionIndex={versionIndex}
                                Referral={referralList}
                                onFormSubmit={this.updateProgramCompletion2}
                                program_completion_response={this.state.program_completion_response}
                                uniqueIdField={uniqueIdField}
                                configType= {Config}
                                subConfigType={subConfig}
                                refferalInfo={refferalInfo}
                            />
                        )}
                    </div>
                </div>
            );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        client: state.client,
        family: state.family,
        program: state.program,
        referral: state.referral,
        user: state.user
    };
};

const mapDispatchToProps = {
    searchDFamily: family.actions.searchDFamily,
    submitPrediction: client.actions.submitPrediction,
    getLocations: dynamicclient.actions.getLocations,
    clearErrors: client.actions.clearErrors,
    clearClient: client.actions.clear,
    updateFormValues: dynamicclient.actions.updateFormValues
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(DynamicFamilyDetailsContainer));